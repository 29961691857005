import React from "react";

import SEO from "../components/seo";
import "../components/layout.css";

const IndexPage = () => (
  <main
    style={{
      padding: `2rem`,
      maxWidth: `40rem`,
      margin: `0 auto`,
      textAlign: `center`
    }}
  >
    <SEO
      title="Home"
      keywords={[
        `rob van groenewoud`,
        `blog`,
        `web development`,
        `software engineering`
      ]}
    />
    <h1>Hey there</h1>
    <p>Thanks for checking out my website.</p>
    <p>
      Unfortunately it was hopelessly outdated and I'm working on a brand new
      one.
    </p>

    <section style={{ display: `grid`, gap: `1rem`, justifyContent: `center` }}>
      <a href="https://twitter.com/robvgroenewoud">Twitter</a>
      <a href="https://www.linkedin.com/in/robvangroenewoud/d">LinkedIn</a>
    </section>
  </main>
);

export default IndexPage;
